/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

$body-bg-dark: #2e2d2d;
$primary: rgb(132, 38, 108); 

$theme-colors: map-merge($theme-colors, (
  "primary": $primary
));

@import "../node_modules/bootstrap/scss/bootstrap";

.table tr td a {
  color: whitesmoke;
  text-decoration-line: none;

}
a {
  color: rgb(162 194 241);
}
.site-wrapper {
  height: 100%;
  overflow-y: hidden;
}

footer {
  text-align: center;
  color: black;
  display: table-footer-group;
}

body {
  min-height: 100%;
  min-width: 100%;
  display: table;
}


/* Fade in */
.fade-in-image {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


/* Override bootstrap styles for auth forms */

.card .card-header h2 {
  color: #fff;
}

.card {
  border: 0px;
  border-radius: 20px !important;
}

.card-header {
  /* background-color: darkslategrey; */
  border-bottom: none;
  border-radius: 20px 20px 0px 0px !important;
}

.idp-title {
  border-left: 5px solid $primary;
}

.pb-6 {
  padding-bottom: 100px;
}


/* Custom input style */

.form-control:not([type="file"]){
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  padding-left: .25rem;
  box-shadow: none;
}

.form-control.is-valid:focus, .form-control.is-invalid:focus{
  border-color: inherit;
  box-shadow: none;

}

.custom-file-input {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.form-control::placeholder {
  opacity: 0.6;
  padding-bottom: .25rem;
  color: rgba(240, 248, 255, 0.37) !important;
}

.form-control.is-invalid {
  /* border-color: rgb(252, 201, 201); */
}

.form-control:focus {
  box-shadow: 0 4px 2px -2px rgba(0, 123, 255, .25);
}

.form-group label {
  margin-bottom: 0;
}

.custom-file.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.1em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.colored-toast.swal2-icon-success {
  background-color: #62ac37ef !important;
}

.colored-toast.swal2-icon-error {
  background-color: #79322df3 !important;
}

.colored-toast.swal2-icon-warning {
  background-color: #f8bb868e !important;
}

.colored-toast.swal2-icon-info {
  background-color: #3fc2eeb4 !important;
}

.colored-toast.swal2-icon-question {
  background-color: #87adbdb2 !important;
}

.colored-toast .swal2-title {
  color: white;
}

.colored-toast .swal2-close {
  color: white;
}

.colored-toast .swal2-html-container {
  color: white;
}

ng-dropdown-panel.ng-dropdown-panel {
  z-index: 9999;
}

$ng-select-dark:  rgba(41, 40, 40, 0.877);
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option{
  background-color: $ng-select-dark !important;
  color: whitesmoke !important;
}

.ng-select .ng-select-container {
  background-color: transparent!important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-radius: 0 !important;
  border-color: #495057 !important;
  color: whitesmoke !important;
}
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
